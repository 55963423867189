/* eslint-disable prettier/prettier */
import axios, { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };
  return config;
});

httpApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    console.error('Error object:', error);
    
    if (error && error.response) {
      throw new ApiError<ApiErrorData>(
        error.response.data?.message,
        error.response.data
      );
    } else {
      // Handle cases where error or response is undefined
      const defaultError: ApiErrorData = { message: 'Unknown error occurred' };
      throw new ApiError<ApiErrorData>(defaultError.message, defaultError);
    }
  }
);

export interface ApiErrorData {
  message: string;
}