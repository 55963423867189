/* eslint-disable prettier/prettier */
export const paths = {
    nftDashboard: '/',
    // auth: {
    //   login: '/auth/login',
    //   signUp: '/auth/sign-up',
    //   lock: '/auth/lock',
    //   forgotPassword: '/auth/forgot-password',
    //   securityCode: '/auth/security-code',
    //   newPassword: '/auth/new-password',
    // },
    profile:{
      profilePage: '/profile',
    },
    enrollemt:{
      enrollmentPage: '/enrollment',
    },

    policy:{
      policyPage: '/policy',
    },
    groups:{
      groupPage: '/groups',
    },
    jobs:{
      jobPage: '/jobs',
    },
    users:{
        userList: '/users/list',
        userAdd: '/users/add',
        userEdit: '/users/edit',
    },
    roles:{
      roleList:'/roles/list',
      roleAdd: '/roles/add',
      roleEdit:'/roles/edit',
  },
    device:{
      deviceList: '/devices/list',
      deviceAdd: '/devices/add',
      deviceEdit: '/devices/edit',
    },
    fleet:{
      fleetList: '/fleets/list',
      fleetAdd: '/fleets/add',
      fleetEdit: '/fleets/edit',
    },
    projects:{
      projectList: '/projects/list',
      projectAdd: '/projects/add',
      projectEdit: '/projects/edit',
    },

    maps:{
      googleMaps: '/maps/google-maps',
    },

    policies:{
      policyList: '/policy/list',
      policyAdd: '/policy/add',
      policyEdit: '/policy/edit',
    },



  };