/* eslint-disable prettier/prettier */
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPages/LoginPage';
import SignUpPage from '@app/pages/SecurityPages/SignUpPage';
import ForgotPasswordPage from '@app/pages/SecurityPages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityPages/SecurityCodePage';
import NewPasswordPage from '@app/pages/SecurityPages/NewPasswordPage';
import LockPage from '@app/pages/LoginPages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';

import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import ProfileLayout from '@app/components/profile/ProfileLayout';
const GoogleMaps = React.lazy(() => import('@app/pages/Maps/LeafletMapsPage/LeafletMapsPage'));


// User Management Routes
const UserListPage = React.lazy(() => import('@app/pages/UsersPages/UsersList'));
const RoleListPage = React.lazy(() => import('@app/pages/RolePages/RoleList'));

// Device Routes
const DeviceListPage = React.lazy(() => import('@app/pages/DevicePages/DeviceList'));

// Fleet Routes
const FleetListPage = React.lazy(() => import('@app/pages/FleetPages/FleetList'));

// Project Routes
const ProjectListPage = React.lazy(() => import('@app/pages/ProjectPages/ProjectList'));

// Policy Routes
const PolicyListPage = React.lazy(() => import('@app/pages/PolicyPages/PolicyList'));

// Profile Routes
const PersonalInfoPage = React.lazy(() => import('@app/pages/ProfilePage/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecurityPages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationPage/NotificationsPage'));


// Enrollment Routes
const EnrollmentPage = React.lazy(() => import('@app/pages/EnrollmentPages/QR-EnrollmentPage'));
const GroupsPage = React.lazy(() => import('@app/pages/GroupsPages/GroupsList'));
const JobsPage = React.lazy(() => import('@app/pages/JobsPages/JobsList'));
const PolicyPage = React.lazy(() => import('@app/pages/PolicyPages/PolicyList'));

// Maps
const Google = withLoading(GoogleMaps);


const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));


const ServerErrorPage = React.lazy(() => import('@app/pages/SecurityPages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/SecurityPages/Error404Page'));


const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';


const NftDashboard = withLoading(NftDashboardPage);

// User Management Routes
const UserList = withLoading(UserListPage);
const RoleList = withLoading(RoleListPage);

// Device Routes
const DeviceList = withLoading(DeviceListPage);

// Fleet Routes
const FleetList = withLoading(FleetListPage);

// Projects Routes
const ProjectList = withLoading(ProjectListPage);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);

// Enrollment Routes
const Enrollment = withLoading(EnrollmentPage);
const Groups = withLoading(GroupsPage);
const Jobs = withLoading(JobsPage);
const Policy = withLoading(PolicyPage);


const DataTables = withLoading(DataTablesPage);
const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );
  

  return (
    <BrowserRouter >
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<NftDashboard />} />
    
          {/* User Management Routes */}
          <Route path="/users/list" element={<UserList />} />
          <Route path="/roles/list" element={<RoleList />} />

          {/* Device Routes */}
          <Route path="/devices/list" element={<DeviceList />} />

          {/* Fleet Routes */}
          <Route path="/fleets/list" element={<FleetList />} />

          {/* Project Routes */}
          <Route path="/projects/list" element={<ProjectList />} />
          <Route path="/maps/google-maps" element={<Google />} />

          {/* Policy Routes */}
          <Route path="/policy/list" element={<Policy />} />

          {/* Enrollment Routes */}
          <Route path="/enrollment" element={<Enrollment />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/jobs" element={<Jobs />} />


          <Route path="data-tables" element={<DataTables />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
