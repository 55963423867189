/* eslint-disable prettier/prettier */
import React from 'react';
import {

  HomeOutlined,
  UserOutlined,
  GroupOutlined,
  QrcodeOutlined,
  FileJpgOutlined,
  AndroidOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { paths } from './paths';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  allowedRoles?: string[];
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  // nft-dashboard
  {
    title: 'sideBar.homePages',
    key: 'home',
    url: paths.nftDashboard,
    icon: <HomeOutlined />,
  },



      //User Pages
  {
    title: 'sideBar.userPages',
    key: 'users',
    icon: <UserOutlined />,
    children: [
      {
        title: 'subMenu.usersList',
        key: 'userList',
        url: paths.users.userList,
      },
      {
        title: 'subMenu.rolesList',
        key: 'roleList',
        url: paths.roles.roleList,
      },
    ],
  },


    //Enrollment Page
    {
      title: 'sideBar.enrollmentPages',
      key: 'enrollment',
      icon: <QrcodeOutlined />,
      children: [
        {
          title: 'subMenu.enrollmentPage',
          key: 'QRCode',
          url: paths.enrollemt.enrollmentPage,
        },
      ],
    },

    //Policy Page
    {
      title: 'sideBar.PolicyPages',
      key: 'policyMenu',
      icon: <FileAddOutlined />,
      children: [
        {
          title: 'subMenu.policyList',
          key: 'policySubMenut',
          url: paths.policies.policyList,
        },
      ],
    },

     //Device Pages
    {
      title: 'sideBar.devicePages',
      key: 'devices',
      icon: <AndroidOutlined />,
      children: [
        {
          title: 'subMenu.deviceList',
          key: 'deviceList',
          url: paths.device.deviceList,
        },
      ],
    },
        //Groups Page
    {
      title: 'sideBar.projectPages',
      key: 'projects',
      icon: <GroupOutlined />,
      children: [
        {
          title: 'subMenu.groupList',
          key: 'groupSub',
          url: paths.groups.groupPage,
        },
        {
          title: 'subMenu.projectList',
          key: 'projectSub',
          url: paths.projects.projectList,
        },
        {
          title: 'common.googleMap',
          key: 'google-maps',
          url: paths.maps.googleMaps,
        },
      ],
    },


  // // Pages
  // {
  //   title: 'common.pages',
  //   key: 'pages',
  //   icon: <LayoutOutlined />,
  //   children: [
  //     {
  //       title: 'common.profilePage',
  //       key: 'profile',
  //       url: '/profile',
  //     },
  //     {
  //       title: 'common.serverError',
  //       key: 'serverError',
  //       url: '/server-error',
  //     },
  //     {
  //       title: 'common.clientError',
  //       key: '404Error',
  //       url: '/404',
  //     },
  //   ],
  // },

];
