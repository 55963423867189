/* eslint-disable prettier/prettier */
import React from 'react';

export const MoonIcon: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.52162 0.850828C5.60372 0.950601 5.65403 1.0727 5.66602 1.20136C5.67802 1.33002 5.65116 1.45932 5.58891 1.57255C5.1026 2.46543 4.84863 3.46627 4.85036 4.48299C4.85036 7.86533 7.60771 10.6042 11.006 10.6042C11.4493 10.6042 11.8808 10.5579 12.2955 10.4696C12.4227 10.4421 12.5551 10.4526 12.6763 10.4999C12.7974 10.5471 12.902 10.629 12.9769 10.7354C13.0559 10.846 13.0963 10.9796 13.0915 11.1154C13.0868 11.2513 13.0373 11.3817 12.9508 11.4866C12.2911 12.297 11.4588 12.9499 10.5146 13.3978C9.57051 13.8458 8.53831 14.0773 7.49331 14.0757C3.61553 14.0757 0.474609 10.9516 0.474609 7.10239C0.474609 4.2054 2.25284 1.7206 4.78475 0.667453C4.91088 0.61415 5.05089 0.60321 5.18376 0.636276C5.31663 0.669342 5.43519 0.744629 5.52162 0.850828Z"
      fill="currentColor"
    />
  </svg>
);
