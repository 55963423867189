/* eslint-disable prettier/prettier */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addDeferredPrompt } from '@app/store/slices/pwaSlice';

export const usePWA = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      if (e instanceof Event) {
        // Check if the event is an instance of BeforeInstallPromptEvent
        const beforeInstallPromptEvent = e as BeforeInstallPromptEvent;
        
        // Extract relevant information from the event
        const eventData = {
          isTrusted: beforeInstallPromptEvent.isTrusted,
          platforms: beforeInstallPromptEvent.platforms,
          // Add other relevant properties here
        };

        // Dispatch the action with the extracted data as payload
        dispatch(addDeferredPrompt(eventData));
      }
    };

    window.addEventListener('beforeinstallprompt', handler);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, [dispatch]);
};
