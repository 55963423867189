/* eslint-disable prettier/prettier */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrendingActivity } from '@app/api/activity.api';
import * as S from './TrendingCollection.styles';

export const TrendingCollection: React.FC<TrendingActivity> = ({ title, image }) => {
  const { t } = useTranslation();


  return (
    <S.Card padding={0} $img={image}>
      <S.CollectionImage src={image} alt="nft" />
      <S.BidButton type="ghost">{t('nft.bid')}</S.BidButton>
      <S.NftCollectionInfo>
        <S.InfoRow>
          <S.Title level={5}>{title}</S.Title>
        </S.InfoRow>
        <S.InfoRow>
          {/* <S.OwnerText>
            {t('nft.by')} {owner}
          </S.OwnerText> */}
          {/* <S.USDText>{getCurrencyPrice(formatNumberWithCommas(usd_value), CurrencyTypeEnum.USD)}</S.USDText> */}
        </S.InfoRow>
      </S.NftCollectionInfo>
    </S.Card>
  );
};
