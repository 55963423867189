export interface HealthFactor {
  value: number;
  name: string;
  description: string;
}

export const deviceChartData: HealthFactor[] = [
  {
    value: 50,
    name: 'medical-dashboard.devices.lifestyle.title',
    description: 'medical-dashboard.devices.lifestyle.description',
  },
  {
    value: 20,
    name: 'medical-dashboard.devices.ecology.title',
    description: 'medical-dashboard.devices.ecology.description',
  },
  {
    value: 20,
    name: 'medical-dashboard.devices.genetics.title',
    description: 'medical-dashboard.devices.genetics.description',
  },
];
